import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashs",
    title: "Dashboards",
    type: "collapse",
    icon: <Icon.TrendingUp size={19} />,
    badge: "primary",
    permissions: ["mas", "adm", "dashboard", "purchase-dashboard", "product-dashboard", "tool-dashboard", "employee-dashboard"],
    //badgeText: "5",
    children: [
      {
        id: "dashboard",
        title: "Execuções",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "dashboard"],
        navLink: "/dashboard"
      },
      /*{
        id: "phase",
        title: "Plano de Ação",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "phase-dashboard"],
        navLink: "/phase-dashboard"
      },*/
      {
        id: "measure",
        title: "Medições",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "measure-dashboard"],
        navLink: "/measure-dashboard"
      },
      /*{
        id: "purchase-dashboard",
        title: "Produtos Previstos",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "purchase-dashboard"],
        navLink: "/purchase-dashboard"
      },*/
      {
        id: "product-dashboard",
        title: "Estoque / Previstos",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "product-dashboard"],
        navLink: "/product-dashboard"
      },
      /*{
        id: "tool-dashboard",
        title: "Uso de Equipamentos",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "tool-dashboard"],
        navLink: "/tool-dashboard"
      },*/
      /*{
        id: "employee-dashboard",
        title: "Anál. Mão de Obra",
        type: "item",
        //icon: <Icon.ChevronRight size={19} />,
        permissions: ["mas", "adm", "employee-dashboard"],
        navLink: "/employee-dashboard"
      },*/
    ]
  },
  {
    id: "operation",
    type: "groupHeader",
    groupTitle: "Operações",
    permissions: ["mas", "adm", "quotes", "list-projects", "shippings", "purchase"],
  },
  /*{
    type: "groupHeader",
    groupTitle: "Projetos",
    permissions: ["adm", "emp", "mov", "quo", "prj", "mas"],
  },*/
  {
    id: "quotes",
    title: "Orçamentos",
    type: "item",
    icon: <Icon.Edit3 size={19} />,
    permissions: ["mas", "adm", "quotes"],
    navLink: "/quotes"
  },
  {
    id: "projects",
    title: "Projetos",
    type: "item",
    icon: <Icon.Trello size={19} />,
    permissions: ["mas", "adm", "list-projects"],
    navLink: "/list-projects"
  },
  {
    id: "shippings",
    title: "Requisições",
    type: "item",
    icon: <Icon.ShoppingBag size={19} />,
    permissions: ["mas", "adm", "shippings"],
    navLink: "/shippings"
  },
  {
    id: "purchases",
    title: "Notas de Entrada",
    type: "item",
    icon: <Icon.File size={19} />,
    permissions: ["mas", "adm", "purchases"],
    navLink: "/purchases"
  },
  {
    id: "reports",
    title: "Relatórios",
    type: "item",
    icon: <Icon.Filter size={19} />,
    permissions: ["mas", "adm", "reports"],
    navLink: "/reports"
  },
  /*{
    id: "reports",
    title: "Relatórios",
    type: "collapse",
    icon: <Icon.Filter size={19} />,
    badge: "primary",
    permissions: ["mas", "adm", "reports"],
    children: [
      {
        id: "v1-reports",
        title: "Relatórios",
        type: "item",
        permissions: ["mas", "adm", "reports"],
        navLink: "/reports"
      },
      {
        id: "v2-reports",
        title: "Relatórios V2",
        type: "item",
        permissions: ["mas", "adm", "reports"],
        navLink: "/v2-reports"
      },
    ]
  },*/
  {
    id: "operation",
    type: "groupHeader",
    groupTitle: "Cadastros",
    permissions: ["mas", "adm", "services", "people", "employees", "tools", "products", "reports", "parameters"],
  },
  {
    id: "compositions",
    title: "Composições",
    type: "item",
    icon: <Icon.GitMerge size={19} />,
    permissions: ["mas", "adm", "services"],
    navLink: "/compositions"
  },
  {
    id: "products",
    title: "Produtos e Serviços",
    type: "item",
    icon: <Icon.Package size={19} />,
    permissions: ["mas", "adm", "products"],
    navLink: "/products"
  },
  /*{
    type: "groupHeader",
    groupTitle: "Cadastros",
    permissions: ["adm", "emp", "mov", "quo", "mas"],
  },*/
  {
    id: "employees",
    title: "Mão de Obra",
    type: "item",
    icon: <Icon.User size={19} />,
    permissions: ["mas", "adm", "employees"],
    navLink: "/employees"
  },
  {
    id: "tools",
    title: "Equipamentos",
    type: "item",
    icon: <Icon.Tool size={19} />,
    permissions: ["mas", "adm", "tools"],
    navLink: "/tools"
  },
  {
    id: "people",
    title: "Clientes e Fornecedores",
    type: "item",
    icon: <Icon.Users size={19} />,
    permissions: ["mas", "adm", "people"],
    navLink: "/people"
  },
  {
    id: "omie-header",
    type: "groupHeader",
    groupTitle: "Espaço Omie",
    permissions: ["mas", "adm", "omie"],
  },
  {
    id: "omie",
    title: "Omie",
    type: "item",
    icon: <Icon.Play size={19} />,
    permissions: ["mas", "adm", "omie"],
    navLink: "/omie"
  },
  {
    id: "system",
    type: "groupHeader",
    groupTitle: "Outros",
    permissions: ["mas", "adm", "parameters", "reports"],
  },
  {
    id: "parameters",
    title: "Parâmetros",
    type: "item",
    icon: <Icon.Sliders size={19} />,
    permissions: ["mas", "adm", "parameters"],
    navLink: "/parameters"
  },

  /*{
    id: "import",
    title: "Importações",
    type: "item",
    icon: <Icon.UploadCloud size={19} />,
    permissions: ["mas", "adm", "parameters"],
    navLink: "/parameters"
  },*/

  {
    id: "contact",
    title: "Central de Ajuda",
    type: "collapse",
    icon: <Icon.HelpCircle size={19} />,
    permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
    //navLink: "/contact"
    children: [
      {
        id: "contact1",
        title: "Guia de Implantação",
        type: "external-link",
        //icon: <Icon.FileText size={19} />,
        permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
        newTab: true,
        navLink: "https://rdobras.notion.site/Guia-Inicial-RDObras-110dcfa4a59c4941951c2957f4380ae0",
      },
      {
        id: "contact2",
        title: "Vídeos",
        type: "external-link",
        //icon: <Icon.FileText size={19} />,
        permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
        newTab: true,
        navLink: "https://rdobras.tawk.help/category/trilhas-de-conhecimento-rdobras",
      },
      {
        id: "contact3",
        title: "Artigos",
        type: "external-link",
        //icon: <Icon.FileText size={19} />,
        permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
        newTab: true,
        navLink: "https://rdobras.tawk.help/",
      },
      {
        id: "contact4",
        title: "Chat",
        type: "external-link",
        //icon: <Icon.MessageCircle size={19} />,
        permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
        navLink: "javascript:void(Tawk_API.toggle())",
      },
    ]
  },

  /*{
    id: "help",
    type: "groupHeader",
    groupTitle: "Ajuda",
    permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
  },*/

  {
    id: "my-account",
    title: "Gerenciar Conta",
    icon: <Icon.EyeOff size={19} />,
    type: "item",
    permissions: ["mas", "adm", "quotes", "list-projects", "purchase", "services", "people", "employees", "tools", "products", "reports", "parameters", "manage-users"],
    navLink: "/my-account",
    disabled: true,
    hidden: true
  },
  {
    id: "other-accounts",
    title: "Gerencia Usuários",
    icon: <Icon.EyeOff size={19} />,
    type: "item",
    permissions: ["mas", "adm", "manage-users"],
    navLink: "/other-accounts",
    disabled: true,
    hidden: true
  },
]

export default navigationConfig
